import { useMemo } from 'react'
import * as styles from './style.css'
import { Link } from '@remix-run/react'
import { CompanyIcon } from '~/components/ui/icons/CompanyIcon'
import { SvgIcon } from '~/components/ui/icons/SvgIcon'
import type { Project } from 'startup-proto/startuptsnode/startup/msg/db/v1/db_pb'
import { getProjectIcon } from '~/utils/image'
import imgLogoLight from '~/assets/img/img-logo-light.svg'

type Props = {
  project: Project.AsObject
}

export const LayoutSideMenu = ({ project }: Props) => {
  const projectIcon = useMemo(() => {
    return getProjectIcon(project)
  }, [project])

  return (
    <div className={styles.layoutSideMenu}>
      <div className={styles.layoutSideMenu_container}>
        <div className={styles.layoutSideMenuLogo}>
          <Link to="/mypage" className={styles.layoutSideMenuLogo_link}>
            <img
              src={imgLogoLight}
              alt="AUBA for Startup"
              className={styles.layoutSideMenuLogo_image}
              width="164"
              height="36"
            />
          </Link>
        </div>

        <div className={styles.layoutSideMenuCompany}>
          <div className={styles.layoutSideMenuCompany_container}>
            <Link to="/mypage" className={styles.layoutSideMenuCompanyLink}>
              <div className={styles.layoutSideMenuCompanyLink_container}>
                <CompanyIcon
                  className={styles.layoutSideMenuCompanyLink_icon}
                  src={projectIcon?.path}
                  alt={projectIcon?.name}
                />
                <span className={styles.layoutSideMenuCompanyLink_text}>{project.profile?.name}</span>
              </div>
            </Link>
          </div>
        </div>

        <div className={styles.layoutSideMenuMainNavigation}>
          <nav className={styles.layoutSideMenuMainNavigation_navigation}>
            <div className={styles.layoutSideMenuMain}>
              <ul className={styles.layoutSideMenuMain_items}>
                <li className={styles.layoutSideMenuMain_item}>
                  <div className={styles.layoutSideMenuMain_container}>
                    <Link to="/mypage" className={styles.layoutSideMenuMainLink}>
                      <SvgIcon variant="panelDashboard" className={styles.layoutSideMenuMainLink_icon} />
                      <span className={styles.layoutSideMenuMainLink_text}>ダッシュボード</span>
                    </Link>
                  </div>
                </li>
                <li className={styles.layoutSideMenuMain_item}>
                  <div className={styles.layoutSideMenuMain_container}>
                    <Link to="/mypage/projects/edit/step1" className={styles.layoutSideMenuMainLink}>
                      <SvgIcon variant="documentPen" className={styles.layoutSideMenuMainLink_icon} />
                      <span className={styles.layoutSideMenuMainLink_text}>基本情報の登録</span>
                    </Link>
                  </div>
                </li>
                <li className={styles.layoutSideMenuMain_item}>
                  <div className={styles.layoutSideMenuMain_container}>
                    <Link to="/mypage/projects/proposer" className={styles.layoutSideMenuMainLink}>
                      <SvgIcon variant="documentCheck" className={styles.layoutSideMenuMainLink_icon} />
                      <span className={styles.layoutSideMenuMainLink_text}>プログラム応募管理</span>
                    </Link>
                  </div>
                </li>
              </ul>
            </div>

            <div className={styles.layoutSideMenuSub}>
              <ul className={styles.layoutSideMenuSub_items}>
                <li className={styles.layoutSideMenuSub_item}>
                  <div className={styles.layoutSideMenuSub_container}>
                    <Link to="/mypage/accounts" className={styles.layoutSideMenuSubLink}>
                      <span className={styles.layoutSideMenuSubLink_text}>アカウント管理</span>
                    </Link>
                  </div>
                </li>
                <li className={styles.layoutSideMenuSub_item}>
                  <div className={styles.layoutSideMenuSub_container}>
                    <a
                      href="https://inquiry.eiicon.net/l/864992/2020-09-07/nrd6p"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.layoutSideMenuSubLink}
                    >
                      <span className={styles.layoutSideMenuSubLink_text}>お問い合わせ</span>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  )
}
