import * as styles from './style.css'
import { LayoutInner } from '~/components/ui/layouts/LayoutInner'
import { BaseButton } from '~/components/ui/buttons/BaseButton'
import imgLogoLight from '~/assets/img/img-logo-light.svg'

export const LayoutFooter = () => {
  return (
    <footer className={styles.layoutFooter}>
      <div className={styles.layoutFooter_wrapper}>
        <div className={styles.layoutFooter_contents}>
          <LayoutInner>
            <div className={styles.layoutFooterContents}>
              <figure className={styles.layoutFooterContentsLogo}>
                <img
                  src={imgLogoLight}
                  alt="AUBA for Startup"
                  className={styles.layoutFooterContentsLogo_image}
                  width="164"
                  height="36"
                />
              </figure>
            </div>
          </LayoutInner>
        </div>

        <div className={styles.layoutFooter_copy}>
          <LayoutInner>
            <div className={styles.layoutFooterCopy}>
              <p className={styles.layoutFooterCopy_paragraph}>
                <small className={styles.layoutFooterCopy_text} lang="en" translate="no">
                  &copy; 株式会社eiicon
                </small>
              </p>

              <div className={styles.layoutFooterContact}>
                <div className={styles.layoutFooterContact_container}>
                  <span className={styles.layoutFooterContact_text}>
                    サービスや使い方についてのお問い合わせはこちら
                  </span>
                  <div className={styles.layoutFooterContact_button}>
                    <BaseButton
                      text="お問い合わせ"
                      variant="outlined"
                      color="primary"
                      size="small"
                      url="https://inquiry.eiicon.net/l/864992/2020-09-07/nrd6p"
                      target="_blank"
                      rel="noopener"
                    />
                  </div>
                </div>
              </div>
            </div>
          </LayoutInner>
        </div>
      </div>
    </footer>
  )
}
