import { useContext } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import * as styles from './style.css'
import { PopupMessageContext } from '~/providers/PopupMessageProvider'

export const LayoutPopupMessage = () => {
  const popupMessage = useContext(PopupMessageContext)

  return (
    <div className={styles.layoutPopupMessage}>
      <div className={styles.layoutPopupMessage_container}>
        <div className={styles.layoutPopupMessage_items}>
          <AnimatePresence mode="sync">
            {popupMessage.map((item, i) => (
              <motion.div
                key={i}
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                }}
                exit={{
                  opacity: 0,
                }}
                transition={{
                  duration: 0.3,
                }}
                className={styles.layoutPopupMessage_item}
              >
                <div
                  className={[
                    styles.layoutPopupMessage_contents,
                    item.type && styles[`layoutPopupMessage_contents__${item.type}`],
                  ]
                    .filter(Boolean)
                    .join(' ')}
                >
                  <span className={styles.layoutPopupMessage_text}>{item.text}</span>
                </div>
              </motion.div>
            ))}
          </AnimatePresence>
        </div>
      </div>
    </div>
  )
}
