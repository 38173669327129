import { StrictMode, type ReactNode } from 'react'
import { PopupMessageProvider } from './PopupMessageProvider'

export const AppProvider = ({ children }: { children: ReactNode }) => {
  return (
    <StrictMode>
      <PopupMessageProvider>{children}</PopupMessageProvider>
    </StrictMode>
  )
}
