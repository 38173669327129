import { useEffect, useState, useMemo, useContext } from 'react'
import { Link, Form, useFetcher } from '@remix-run/react'
import { motion, AnimatePresence } from 'framer-motion'
import type { User } from 'startup-proto/startuptsnode/startup/msg/db/v1/db_pb'
import * as styles from './style.css'
import { LayoutInner } from '~/components/ui/layouts/LayoutInner'
import { BaseButton } from '~/components/ui/buttons/BaseButton'
import { UserIcon } from '~/components/ui/icons/UserIcon'
import { SvgIcon } from '~/components/ui/icons/SvgIcon'
import { TransitionFade } from '~/components/ui/transitions/TransitionFade'
import { type action as mypageAction } from '~/routes/mypage/route'
import { getUserIcon } from '~/utils/image'
import imgLogo from '~/assets/img/img-logo.svg'
import { SetPopupMessageContext } from '~/providers/PopupMessageProvider'

type Props = {
  user?: User.AsObject
}

export const LayoutHeader = ({ user }: Props) => {
  const fetcher = useFetcher<typeof mypageAction>()
  const [isShowOuterMenu, setIsShowOuterMenu] = useState(false)
  const [isShowPopupMenu, setIsShowPopupMenu] = useState(false)
  const [isHideReSendButton, setIsHideReSendButton] = useState(false)
  const setPopupMessage = useContext(SetPopupMessageContext)

  const isSignIn = useMemo(() => {
    return !!user
  }, [user])

  const isAuthed = useMemo(() => {
    return user ? user.isAuthed : false
  }, [user])

  const userIcon = useMemo(() => {
    if (!user) {
      return undefined
    }

    return getUserIcon(user)
  }, [user])

  const showOuterMenu = () => {
    setIsShowOuterMenu(true)
  }

  const hideOuterMenu = () => {
    setIsShowOuterMenu(false)
  }

  const toggleOuterMenu = () => {
    if (!isShowOuterMenu) {
      showOuterMenu()

      return
    }

    hideOuterMenu()
  }

  const showPopupMenu = () => {
    setIsShowPopupMenu(true)
  }

  const hidePopupMenu = () => {
    setIsShowPopupMenu(false)
  }

  const togglePopupMenu = () => {
    if (!isShowPopupMenu) {
      showPopupMenu()

      return
    }

    hidePopupMenu()
  }

  useEffect(() => {
    setIsShowOuterMenu(false)
    setIsShowPopupMenu(false)
  }, [isSignIn])

  useEffect(() => {
    if (isHideReSendButton || fetcher.state !== 'idle') {
      return
    }

    const fetcherData = fetcher.data

    if (typeof fetcherData === 'string' || fetcherData?.name !== 'resendAuthConfirmEmail' || !fetcherData?.isSuccess) {
      return
    }

    setIsHideReSendButton(true)

    setPopupMessage({
      text: '認証メールを送信しました',
    })
  }, [fetcher, isHideReSendButton, setPopupMessage])

  return (
    <header className={[styles.layoutHeader, isSignIn && styles.layoutHeader__signIn].filter(Boolean).join(' ')}>
      <div className={styles.layoutHeader_wrapper}>
        <LayoutInner size="large">
          <div className={styles.layoutHeader_container}>
            <div className={styles.layoutHeaderLogo}>
              <Link to={isSignIn ? '/mypage' : '/'} className={styles.layoutHeaderLogo_link}>
                <img
                  src={imgLogo}
                  alt="AUBA for Startup"
                  className={styles.layoutHeaderLogo_image}
                  width="120"
                  height="26"
                />
              </Link>
            </div>

            <div className={styles.layoutHeader_contents}>
              {isSignIn ? (
                <div className={styles.layoutHeaderSignInMenu}>
                  <div className={styles.layoutHeaderPopupMenu}>
                    <button type="button" className={styles.layoutHeaderPopupMenuButton} onClick={togglePopupMenu}>
                      <UserIcon
                        className={styles.layoutHeaderPopupMenuButton_user}
                        size="small"
                        tag="span"
                        src={userIcon?.path}
                        alt={userIcon?.name}
                      />
                      <span className={styles.layoutHeaderPopupMenuButton_name}>{user?.profile?.name}</span>
                      <SvgIcon variant="triangleDown" color="sub" className={styles.layoutHeaderPopupMenuButton_icon} />
                    </button>

                    <AnimatePresence mode="wait">
                      {isShowPopupMenu && (
                        <motion.div
                          initial={{
                            opacity: 0,
                          }}
                          animate={{
                            opacity: 1,
                          }}
                          exit={{
                            opacity: 0,
                          }}
                          transition={{
                            duration: 0.3,
                          }}
                          className={styles.layoutHeaderPopupMenu_wrapper}
                        >
                          <div className={styles.layoutHeaderPopupMenu_container}>
                            <div className={styles.layoutHeaderPopupMenuMain}>
                              <ul className={styles.layoutHeaderPopupMenuMain_items}>
                                <li className={styles.layoutHeaderPopupMenuMain_item}>
                                  <div className={styles.layoutHeaderPopupMenuMain_container}>
                                    <Link
                                      to="/mypage/accounts"
                                      className={styles.layoutHeaderPopupMenuMainLink}
                                      onClick={hidePopupMenu}
                                    >
                                      <span className={styles.layoutHeaderPopupMenuMainLink_text}>
                                        プロフィール編集
                                      </span>
                                    </Link>
                                  </div>
                                </li>
                                <li className={styles.layoutHeaderPopupMenuMain_item}>
                                  <Form
                                    method="post"
                                    action="/logout"
                                    className={styles.layoutHeaderPopupMenuMain_container}
                                  >
                                    <button
                                      type="submit"
                                      className={styles.layoutHeaderPopupMenuMainLink}
                                      onClick={hidePopupMenu}
                                    >
                                      <span className={styles.layoutHeaderPopupMenuMainLink_text}>ログアウト</span>
                                    </button>
                                  </Form>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>

                  <div className={styles.layoutHeaderMenuButton}>
                    <button
                      type="button"
                      className={[
                        styles.layoutHeaderMenuButton_button,
                        isShowOuterMenu && styles.layoutHeaderMenuButton_button__active,
                      ]
                        .filter(Boolean)
                        .join(' ')}
                      title={isShowOuterMenu ? 'メニューを閉じる' : 'メニューを開く'}
                      aria-label={isShowOuterMenu ? 'メニューを閉じる' : 'メニューを開く'}
                      onClick={toggleOuterMenu}
                    >
                      <span className={styles.layoutHeaderMenuButton_container}>
                        <span className={styles.layoutHeaderMenuButton_icons}>
                          <span className={styles.layoutHeaderMenuButton_icon} />
                          <span className={styles.layoutHeaderMenuButton_icon} />
                          <span className={styles.layoutHeaderMenuButton_icon} />
                        </span>
                      </span>
                    </button>
                  </div>
                </div>
              ) : (
                <div className={styles.layoutHeaderMenu}>
                  <div className={styles.layoutHeaderMenu_container}>
                    <ul className={styles.layoutHeaderMenu_items}>
                      <li className={styles.layoutHeaderMenu_item}>
                        <BaseButton text="会員登録" url="/register" size="small" />
                      </li>
                      <li className={styles.layoutHeaderMenu_item}>
                        <BaseButton text="ログイン" url="/login" variant="outlined" size="small" color="sub" />
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        </LayoutInner>
      </div>

      <AnimatePresence mode="wait">
        {isShowOuterMenu && (
          <motion.div
            initial={{
              right: '-100%',
            }}
            animate={{
              right: 0,
            }}
            exit={{
              right: '-100%',
            }}
            transition={{
              duration: 0.3,
            }}
            className={styles.layoutHeaderSignInOuterMenu}
          >
            <div className={styles.layoutHeaderSignInOuterMenu_container}>
              <div className={styles.layoutHeaderSignInOuterMenuUser}>
                <div className={styles.layoutHeaderSignInOuterMenuUser_container}>
                  <UserIcon
                    className={styles.layoutHeaderSignInOuterMenuUser_icon}
                    src={userIcon?.path}
                    alt={userIcon?.name}
                  />
                  <span className={styles.layoutHeaderSignInOuterMenuUser_name}>{user?.profile?.name}</span>
                </div>
              </div>

              <div className={styles.layoutHeaderSignInOuterMenuNavigation}>
                <nav className={styles.layoutHeaderSignInOuterMenuNavigation_navigation}>
                  <div className={styles.layoutHeaderSignInOuterMenuNavigationMain}>
                    <ul className={styles.layoutHeaderSignInOuterMenuNavigationMain_items}>
                      <li className={styles.layoutHeaderSignInOuterMenuNavigationMain_item}>
                        <div className={styles.layoutHeaderSignInOuterMenuNavigationMain_container}>
                          <Link
                            to="/mypage"
                            className={styles.layoutHeaderSignInOuterMenuNavigationMainLink}
                            onClick={hideOuterMenu}
                          >
                            <SvgIcon
                              variant="panelDashboard"
                              className={styles.layoutHeaderSignInOuterMenuNavigationMainLink_icon}
                            />
                            <span className={styles.layoutHeaderSignInOuterMenuNavigationMainLink_text}>
                              ダッシュボード
                            </span>
                          </Link>
                        </div>
                      </li>
                      <li className={styles.layoutHeaderSignInOuterMenuNavigationMain_item}>
                        <div className={styles.layoutHeaderSignInOuterMenuNavigationMain_container}>
                          <Link
                            to="/mypage/projects/edit/step1"
                            className={styles.layoutHeaderSignInOuterMenuNavigationMainLink}
                            onClick={hideOuterMenu}
                          >
                            <SvgIcon
                              variant="documentPen"
                              className={styles.layoutHeaderSignInOuterMenuNavigationMainLink_icon}
                            />
                            <span className={styles.layoutHeaderSignInOuterMenuNavigationMainLink_text}>
                              基本情報の登録
                            </span>
                          </Link>
                        </div>
                      </li>
                      <li className={styles.layoutHeaderSignInOuterMenuNavigationMain_item}>
                        <div className={styles.layoutHeaderSignInOuterMenuNavigationMain_container}>
                          <Link
                            to="/mypage/projects/proposer"
                            className={styles.layoutHeaderSignInOuterMenuNavigationMainLink}
                            onClick={hideOuterMenu}
                          >
                            <SvgIcon
                              variant="documentCheck"
                              className={styles.layoutHeaderSignInOuterMenuNavigationMainLink_icon}
                            />
                            <span className={styles.layoutHeaderSignInOuterMenuNavigationMainLink_text}>
                              プログラム応募管理
                            </span>
                          </Link>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div className={styles.layoutHeaderSignInOuterMenuNavigationSub}>
                    <ul className={styles.layoutHeaderSignInOuterMenuNavigationSub_items}>
                      <li className={styles.layoutHeaderSignInOuterMenuNavigationSub_item}>
                        <div className={styles.layoutHeaderSignInOuterMenuNavigationSub_container}>
                          <Link
                            to="/mypage/accounts"
                            className={styles.layoutHeaderSignInOuterMenuNavigationSubLink}
                            onClick={hideOuterMenu}
                          >
                            <span className={styles.layoutHeaderSignInOuterMenuNavigationSubLink_text}>
                              アカウント管理
                            </span>
                          </Link>
                        </div>
                      </li>
                      <li className={styles.layoutHeaderSignInOuterMenuNavigationSub_item}>
                        <div className={styles.layoutHeaderSignInOuterMenuNavigationSub_container}>
                          <a
                            href="https://inquiry.eiicon.net/l/864992/2020-09-07/nrd6p"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.layoutHeaderSignInOuterMenuNavigationSubLink}
                          >
                            <span className={styles.layoutHeaderSignInOuterMenuNavigationSubLink_text}>
                              お問い合わせ
                            </span>
                          </a>
                        </div>
                      </li>
                      <li className={styles.layoutHeaderSignInOuterMenuNavigationSub_item}>
                        <Form
                          method="post"
                          action="/logout"
                          className={styles.layoutHeaderSignInOuterMenuNavigationSub_container}
                        >
                          <button
                            type="submit"
                            className={styles.layoutHeaderSignInOuterMenuNavigationSubLink}
                            onClick={hideOuterMenu}
                          >
                            <span className={styles.layoutHeaderSignInOuterMenuNavigationSubLink_text}>ログアウト</span>
                          </button>
                        </Form>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence mode="wait">
        {isShowOuterMenu && (
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
            transition={{
              duration: 0.3,
            }}
            className={styles.layoutHeaderOverlay}
            onClick={hideOuterMenu}
          >
            <div className={styles.layoutHeaderOverlay_container} />
          </motion.div>
        )}
      </AnimatePresence>

      <TransitionFade isShow={isSignIn && !isAuthed} className={styles.layoutHeaderAuthed}>
        <LayoutInner size="large">
          <div className={styles.layoutHeaderAuthed_wrapper}>
            <div className={styles.layoutHeaderAuthed_container}>
              <p className={styles.layoutHeaderAuthed_paragraph}>
                メールアドレスが認証されていません。メールを確認してください。
              </p>
              <TransitionFade isShow={!isHideReSendButton} className={styles.layoutHeaderAuthedReSend}>
                <fetcher.Form method="post" action="/mypage" className={styles.layoutHeaderAuthedReSend_form}>
                  <BaseButton
                    buttonType="submit"
                    text={
                      fetcher.state === 'submitting'
                        ? '送信中'
                        : fetcher.state === 'loading'
                          ? '処理中'
                          : '認証メールを再送する'
                    }
                    variant="outlined"
                    size="small"
                    color="light"
                    className={styles.layoutHeaderAuthedReSend_button}
                    isDisabled={fetcher.state !== 'idle' || isHideReSendButton}
                  />
                  <input type="hidden" name="action" value="resendAuthConfirmEmail" />
                </fetcher.Form>
              </TransitionFade>
            </div>
          </div>
        </LayoutInner>
      </TransitionFade>
    </header>
  )
}
